import React from 'react'
import { graphql } from 'gatsby'
import Blog from './blog'
import Layout from '../components/layout'
import Bio from '../components/bio'

export default function Index({ data, location }) {
  // const siteTitle = data?.site.?siteMetadata?.title || `Title`;
  const siteTitle = ''
  return (
    <>
      <Layout location={location} title={siteTitle}>
        <div className="global-wrapper">
          <Bio />
          <Blog data={data} location={location} />
        </div>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          draft
        }
      }
    }
  }
`
